import { CopyButton } from "../../components/ui/copy_button";
import { formatCurrencyAbs, formatCurrencyAbsInt } from "../../components/ui/money";
import Header from "../../components/ui/header";

type RecordType = Record<string, Record<string, any>>;

const allowedKeys: Record<string, string> = {
  controller_calc: 'Розрахунок контролера',
  controller_reading: 'Показання контролера',
  current_reading: 'Показ поточний',
  previous_reading: 'Показання попереднє',
  debt_sum: 'Сума боргу',
  reading_date: 'Дата свідчень',
};

type RenderRecordProps = {
  record: Record<string, any>;
  account: string;
  day: number;
  night: number;
};

const RenderRecord: React.FC<RenderRecordProps> = (
  {
    record,
    account,
    day,
    night,
  }) => {
  // Initialize numeric summary
  const summary: Record<string, number | string> = {};

  // Prepare initial zero values
  Object.keys(allowedKeys).forEach((key) => {
    summary[key] = 0;
  });

  const isDate = (str: any) => /^\d{2}[-.]\d{2}[-.]\d{4}$/.test(str);

  const first = Object.entries(record)[0][1];

  // Aggregate values
  Object.values(record).forEach((mainValue) => {
    Object.keys(allowedKeys).forEach((key) => {
      const raw = mainValue[key];

      if (isDate(raw)) {
        summary[key] = raw;
        return;
      }

      // Try parsing numbers (with comma or dot as decimal separator)
      const normalized = typeof raw === 'string' ? raw.replace(',', '') : raw;
      const parsed = parseFloat(normalized);

      if (!isNaN(parsed)) {
        let num = summary[key] as number;
        if (num < 0) {
          summary[key] = num + parsed;
        } else {
          summary[key] = num - parsed;
        }
      } else {
        // Just keep the last seen non-numeric value
        summary[key] = raw;
      }
    });
  });

  return (<div className="space-y-6 px-1">
      {Object.entries(record).map(([mainKey, mainValue]) => (
        <div
          key={`title-${mainKey}`}
          className="border rounded-lg p-4 shadow-sm bg-white space-y-2"
        >
          <h2 className="text-lg font-bold text-blue-700 mb-4">{mainKey}</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
            {Object.entries(allowedKeys).map(([key, label]) => (
              <div key={key} className="flex flex-col">
                <span className="text-gray-500 font-medium">{label}</span>
                <span className="text-gray-900">{mainValue[key]?.toString() || "-"}</span>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Vertical Result Summary */}
      <div
        className="border rounded-lg p-4 shadow-sm bg-white space-y-3"
      >
        <h2 className="text-lg font-bold text-blue-700 mb-4">Результат</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
          {Object.entries(allowedKeys).map(([key, label]) => (
            <div key={`results-${key}`} className="flex flex-col">
              <span className="text-gray-500 font-medium">{label}</span>
              <span className="text-gray-900">
            {typeof summary[key] === "number"
              ? summary[key].toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : summary[key] || "-"}
          </span>
            </div>
          ))}
        </div>

        <div className="mt-4 space-y-2 text-sm text-gray-900">
          <div>
            <span className="font-medium">Формула:</span><br/>
            Електроенергія від {first.owner},
            о/р {account}, {formatCurrencyAbsInt(day as number)}/{formatCurrencyAbsInt(night as number)}
            <br/>
            <CopyButton
              text={`Електроенергія від ${first.owner}, о/р ${account}, ${formatCurrencyAbsInt(day as number)}/${formatCurrencyAbsInt(night as number)}`}
            />
          </div>

          <div>
            <span className="font-medium">Борг:</span><br/>
            {formatCurrencyAbs(summary?.debt_sum as number)}
            <br/>
            <CopyButton text={`${formatCurrencyAbs(summary?.debt_sum as number)}`}/>
          </div>

          <div>
            <span className="font-medium">Дата зчитування:</span><br/>
            {summary?.reading_date}
            <br/>
            <CopyButton text={`${summary?.reading_date}`}/>
          </div>
        </div>
      </div>
    </div>
  )
};

export const RecordViewer = ({ record, section, account }: {
  record: RecordType,
  section: string,
  account: string
}) => {

  return (
    <>
      {Object.entries(record).map(([periodKey, periodValue]) => {
        const day = Object.values(periodValue)?.[0]?.controller_calc ? (Object.values(periodValue)?.[0]?.controller_calc !== "" ? Object.values(periodValue)?.[0]?.controller_calc : '0') : '0'
        const night = Object.values(periodValue)?.[1]?.controller_calc ? (Object.values(periodValue)?.[1]?.controller_calc !== "" ? Object.values(periodValue)?.[1]?.controller_calc : '0') : '0'

        return (
          <Header className={"border rounded-lg"} key={`header-${periodKey}`}>{periodKey}
            <RenderRecord
              key={`RenderRecord-${periodKey}`}
              record={periodValue}
              account={account}
              day={day}
              night={night}
            />
          </Header>
        );
      })
      }
    </>
  );
};
