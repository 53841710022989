import { ButtonHTMLAttributes } from "react";

type TabButtonProps = {
  active?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function TabButton({ active, children, ...props }: TabButtonProps) {
  return (
    <button
      className={`${active ? 'bg-blue-600' : 'bg-gray-400'} hover:bg-blue-700 text-white px-4 py-2 rounded-t-lg transition duration-200 ${
        active ? 'ring-2 ring-blue-400' : ''
      }`}
      {...props}
    >
      {children}
    </button>
  );
}
