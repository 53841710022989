import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RecordsPage from "./pages/RecordsPage";
import ProfilePage from "./pages/ProfilePage";
import UploadPage from "./pages/UploadPage";
import { ProfileProvider } from "./context/ProfileContext";
import UsersPage from "./pages/UsersPage";
import EditUserPage from "./pages/EditUserPage";

function App() {
  const token = localStorage.getItem("token");

  return (
    <ProfileProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to={token ? "/records" : "/login"}/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/records" element={<RecordsPage/>}/>
          <Route path="/users" element={<UsersPage/>}/>
          <Route path="/user/:account" element={<EditUserPage/>}/>
          <Route path="/upload" element={<UploadPage/>}/>
        </Routes>
      </Router>
    </ProfileProvider>
  );
}

export default App;
