import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { decodeJwtPayload } from "../helpers/jwt";
import { API } from "../config";

type ProfileType = any; // You can replace `any` with your actual type
type TokenType = string | null; // You can replace `any` with your actual type

interface ProfileContextType {
  profile: ProfileType;
  setProfile: React.Dispatch<React.SetStateAction<ProfileType>>;
  loading: boolean;
  identifier: string;
  setIdentifier: React.Dispatch<React.SetStateAction<string>>;
  token: TokenType;
  setToken: React.Dispatch<React.SetStateAction<TokenType>>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return context;
};

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const [profile, setProfile] = useState<ProfileType>(null);
  const [token, setToken] = useState<TokenType>(localStorage.getItem("token"));
  const [identifier, setIdentifier] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }

    console.log("token--", token);
    fetch(API.PROFILE, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setProfile(data)
        const tokenData = decodeJwtPayload(token)
        const id = tokenData.identifier;
        setIdentifier(id);
      })
      .catch((err) => console.error("Profile fetch error:", err))
      .finally(() => setLoading(false));
  }, [token]);

  return (
    <ProfileContext.Provider value={{ profile, setProfile, loading, identifier, setIdentifier, token, setToken }}>
      {children}
    </ProfileContext.Provider>
  );
};
