import { useLocation, useNavigate } from "react-router-dom";
import { TabButton } from "../../components/ui/tab_button";
import { Button } from "../../components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUpload } from "@fortawesome/free-solid-svg-icons";

type MenuProps = {
  profile: {
    phones?: {main?: string};
    account?: string;
    roles?: string[];
  };
} & React.HTMLAttributes<HTMLDivElement>;

export function Menu({ profile, children, ...props }: MenuProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const navigateToProfile = () => {
    navigate('/profile')
  };

  const navigateToRecords = () => {
    navigate('/records')
  };

  return (
    <>
      {/* Menu bar */}
      <div className="bg-white shadow-md p-4 flex justify-between items-center rounded-b-xl">
        <div className="text-sm">
          <strong>Телефон:</strong> {profile?.phones?.main || "-"}<br/>
          <strong>КОД НС2:</strong> {profile?.account}
        </div>
        <div>
          <Button onClick={handleLogout}>Вийти</Button>
        </div>
      </div>

      {/* Tabs */}
      <div className="bg-white shadow-md flex justify-center items-center">
        <div
          className="flex justify-between items-center rounded-b-xl px-4"
          style={{ alignSelf: "center" }}
        >
          <TabButton onClick={navigateToProfile} active={location.pathname === '/profile'}>Профайл</TabButton>
          <TabButton onClick={navigateToRecords} active={location.pathname === '/records'}>Усі записи</TabButton>
          {profile?.roles?.includes("ADMIN") && (
            <>
              <TabButton onClick={() => navigate("/users")}
                         active={location.pathname === '/users'}>
                <FontAwesomeIcon icon={faUsers} />
              </TabButton>
              <TabButton onClick={() => navigate("/upload")}
                         active={location.pathname === '/upload'}>
                <FontAwesomeIcon icon={faUpload} />
              </TabButton>
            </>
          )}
        </div>
      </div>
    </>
  );
}
