import { ButtonHTMLAttributes } from "react";

type ButtonProps = {
  active?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({ active, children, className = "", ...props }: ButtonProps) {
  return (
    <button
      className={`bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition duration-200 ${className} ${
        active ? 'ring-2 ring-blue-400' : ''
      }`}
      {...props}
    >
      {children}
    </button>
  );
}
