import { useState } from "react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { useNavigate } from "react-router-dom";
import { API } from "../config";
import { Menu } from "../components/ui/menu";
import { useProfile } from "../context/ProfileContext";

export default function UploadPage() {
  const { profile, loading } = useProfile();

  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleUpload = async () => {
    if (!file) {
      setError("Please select an Excel file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await fetch(`${API.BASE_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      });

      const data = await res.json();
      if (res.ok) {
        setMessage("File uploaded successfully.");
        setError("");
      } else {
        setMessage("");
        setError(data.error || "Upload failed.");
      }
    } catch (err) {
      setMessage("");
      setError("Network error");
    }
  };

  if (loading) return <p className="text-center p-4">Завантажується...</p>;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-100">
      <Menu profile={profile}/>

      <div className="flex justify-center items-center min-h-screen">
        <Card className="w-full max-w-lg">
          <CardContent>
            <h1 className="text-xl font-semibold mb-4">Завантажити Excel файл</h1>
            <Input
              type="file"
              accept=".xlsx,.xls"
              onChange={(e) => setFile(e.target.files?.[0] || null)}
            />

            <div className="flex justify-center items-center mt py-4">
              <Button onClick={handleUpload}>Завантажити</Button>
            </div>

            {message && <p className="text-green-600 mt-4 text-sm">{message}</p>}
            {error && <p className="text-red-500 mt-4 text-sm">{error}</p>}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
