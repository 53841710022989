import React from "react";

type HeaderProps = {
  children: React.ReactNode;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ children, className = "" }) => {
  return (
    <div className={`text-2xl font-semibold text-red-700 border-b pb-2 mb-4 bg-gradient-to-b from-gray-450 to-white-50 text-center ${className}`}>
      {children}
    </div>
  );
};

export default Header;
