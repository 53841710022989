import { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { useNavigate } from "react-router-dom";
import { API } from "../config";
import { Menu } from "../components/ui/menu";
import { decodeJwtPayload } from "../helpers/jwt";
import { useProfile } from "../context/ProfileContext";

export default function ProfilePage() {
  const { profile, loading, token, setProfile, setIdentifier } = useProfile();
  const [viber, setViber] = useState("");
  const [telegram, setTelegram] = useState("");
  const [viberSend, setViberSend] = useState(false);
  const [telegramSend, setTelegramSend] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      fetch(API.PROFILE, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            localStorage.removeItem("token");
            navigate("/login");
          } else {
            setProfile(data);
            setViber(data.viber || "");
            setTelegram(data.telegram || "");
            setViberSend(data.viberSend);
            setTelegramSend(data.telegramSend);

            const tokenData = decodeJwtPayload(token)
            const id = tokenData.identifier;
            setIdentifier(id);
          }
        }).catch( (err) => {
          setError(`Помилка сервера ${err}`);
          setTimeout(() => setError(""), 5000);
      });
    } catch (err) {
      setError("Помилка сервера");
      setTimeout(() => setError(""), 5000);
    }
  }, [token, navigate]);

  const handleUpdate = async () => {
    try {
      const res = await fetch(API.PROFILE, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ viber, telegram, viberSend, telegramSend })
      });

      const data = await res.json();
      if (res.ok) {
        setSuccess("Успішно збережено ✅");
        setTimeout(() => setSuccess(""), 5000);
      } else {
        setError(data.error || "Не вдалося оновити");
        setTimeout(() => setError(""), 5000);
      }
    } catch (err) {
      setError("Помилка мережі");
    }
  };

  if (loading) return <p className="text-center p-4">Завантажується...</p>;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-100">

      <Menu profile={profile}/>

      <div className="flex flex-col items-center p-6">
        <Card className="w-full max-w-xl mb-6 rounded-2xl shadow-lg">
          <CardContent>
            <h1 className="text-2xl font-semibold mb-6 text-center">Profile</h1>
            <p className="text-center text-gray-600 mb-4"><strong>Roles:</strong> {profile?.roles?.join(", ")}</p>

            {error && <p className="text-red-500 text-sm text-center">{error}</p>}
            {success && <p className="text-green-600">{success}</p>}

            <div className="space-y-4">

              <div className="flex items-center gap-4">
                <div>
                  <label className="block font-medium mb-1">Viber</label>
                  <Input value={viber} onChange={(e) => setViber(e.target.value)}/>
                </div>
                <label>
                  <input
                    type="checkbox"
                    checked={viberSend}
                    onChange={(e) => setViberSend(e.target.checked)}
                  /> Viber Відправляти?
                </label>
              </div>

              <div className="flex items-center gap-4">
                <div>
                  <label className="block font-medium mb-1">Telegram</label>
                  <Input value={telegram} onChange={(e) => setTelegram(e.target.value)}/>
                </div>

                <label>
                  <input
                    type="checkbox"
                    checked={telegramSend}
                    onChange={(e) => setTelegramSend(e.target.checked)}
                  /> Telegram Відправляти?
                </label>
              </div>

              <Button onClick={handleUpdate}>Оновити</Button>
              {error && <p className="text-red-500 text-sm text-center">{error}</p>}
              {success && <p className="text-green-600">{success}</p>}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
