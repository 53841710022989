import { useEffect, useState } from "react";
import { Card, CardContent } from "../components/ui/card";
import { useNavigate } from "react-router-dom";
import { API } from "../config";
import { Menu } from "../components/ui/menu";
import { useProfile } from "../context/ProfileContext";
import { Button } from "../components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const fields: Record<string, string> = {
  "section": "СТ",
  "account": "Код НС2",
  "name": "ПІБ",
  "phones": "Телефон",
  "actions": "Дії"
}

export default function UsersPage() {
  const { profile, loading, token } = useProfile();
  const [users, setUsers] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }

    fetch(API.USERS, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((res) => res.json())
      .then((data) => {
        setUsers(data?.users);
      });
  }, [token, navigate]);

  if (loading) return <p className="text-center p-4">Завантажується...</p>;

  const editUser = (account: string) => () => {
    navigate(`/user/${account}`)
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-100">

      <Menu profile={profile}/>

      <div className="flex flex-col items-center p-6">
        {users && (
          <Card className="w-full max-w-fit rounded-2xl shadow-md">
            <CardContent>
              <h2 className="text-xs font-semibold mb-4">Користувачі</h2>
              <div className="w-full">
                <table className="text-xs w-full table-fixed border-collapse border border-gray-200 border-slate-500">
                  <thead className="bg-gray-100 text-gray-700 font-semibold">
                  <tr className="text-gray-900">
                    <th className="w-1/6 px-2 py-2 border border-gray-200 text-center">#</th>
                    <th className="w-1/4 px-4 py-2 border border-gray-200 text-left break-words">СТ</th>
                    <th className="w-1/3 px-4 py-2 border border-gray-200 text-left break-words">Код НС2</th>
                    <th className="w-1/3 px-4 py-2 border border-gray-200 text-left break-words">ПІБ</th>
                    <th className="w-1/2 px-4 py-2 border border-gray-200 text-left break-words">Телефон</th>
                    <th className="w-1/6 px-4 py-2 border border-gray-200 text-left break-words">Дії</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Object.entries(users).map(([key, user]: [string, any], index) => (
                    <tr key={key}
                        className={`
                        ${user?.roles?.[0] === "ADMIN" ? 'text-gray-900 bg-white' : 'text-gray-900 bg-white'}
                        ${user?.password === "N" ? 'bg-green-100' : 'bg-white'}
                         hover:bg-gray-50
                         `}
                    >
                      <td className="w-[40px] text-center px-2 border border-gray-200 break-words">
                        {user?.roles?.[0] === "ADMIN" ? <span className="text-red-500">{index + 1}</span> : (index + 1)}
                      </td>
                      <td className="text-left px-2 border border-gray-200 break-words">
                        {user.section}
                      </td>
                      <td className="text-left px-2 border border-gray-200 break-words">
                        {user.account}
                        <br/>
                        {user?.roles?.[0] === "ADMIN" && <span className="text-red-500">Адміністратор</span>}
                      </td>
                      <td className="text-left px-2 border border-gray-200 break-words">
                        {user.name}
                        <br/>
                        {user?.roles?.[0] === "ADMIN" && <span className="text-red-500">Адміністратор</span>}
                      </td>
                      <td className="text-left px-2 border border-gray-200 break-words">
                        {user?.password === "N" ? <><span className="text-red-500">Пароль не встановленно</span><br/></> : <></>}
                        {Object.values(user?.phones).join(", ")}
                      </td>
                      <td className="text-left px-2 border border-gray-200 break-words">
                        <Button className="ml-1 px-2" onClick={editUser(user?.account)}><FontAwesomeIcon icon={faEdit}/></Button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>

            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}

// "ВТ001_А_ЛУГ06": {
//   "account": "ВТ001_А_ЛУГ06",
//     "accounts": {
//     "prefix": "ВТ001_А_ЛУГ06",
//       "raw": "ВТ001_А_Луг06 Опора ЛЕП",
//       "suffix": "Опора ЛЕП"
//   },
//   "name": "Головінова Ірина Олександрівна",
//     "password": "",
//     "phones": {
//     "main": "+380-97-777-18-83"
//   },
//   "section": "Ветеран"
// },
