import { useEffect, useState } from "react";
import { Card, CardContent } from "../components/ui/card";
import { useNavigate } from "react-router-dom";
import { API } from "../config";
import { Menu } from "../components/ui/menu";
import { RecordViewer } from "../components/ui/record";
import { useProfile } from "../context/ProfileContext";

export default function RecordsPage() {
  const { profile, loading, identifier, token } = useProfile();
  const [record, setRecord] = useState<any>(null);
  const [account, setAccount] = useState<any>(null);
  const [section, setSection] = useState<any>(null);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    if (!identifier && !token) {
      navigate("/login");
      return;
    }

    if (!identifier) {
      return;
    }

    fetch(API.RECORD(identifier), {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => res.json())
      .then((res) => {
        setSection(res?.section);
        setAccount(res?.account);
        setRecord(res?.record);
      }).catch((err) => {
      console.error("Помилка сервера", err);
      setError("Помилка сервера")
      setTimeout(() => setError(""), 5000);
    });
  }, [identifier, token, navigate]);

  if (loading) return <p className="text-center p-4">Завантажується...</p>;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-100">

      <Menu profile={profile}/>

      <div className="flex flex-col items-center p-6">
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        {record && (
          <Card className="w-full max-w-4xl rounded-2xl shadow-md">
            <CardContent>
              <h2 className="text-xl font-semibold mb-4">{section} {account} {record?.owner}</h2>
              <RecordViewer record={record} section={section} account={account}/>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
