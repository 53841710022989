import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../config";
import { useProfile } from "../context/ProfileContext";

export default function LoginPage() {
  const { setToken } = useProfile();
  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const res = await fetch(API.LOGIN, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ u:input, p: password }),
      });

      const data = await res.json();
      if (res.ok) {
        localStorage.setItem("token", data.token);
        setToken(data.token);
        setTimeout( () => {
          navigate("/records");
        }, 100);
      } else {
        setError(data.error || "Помилка входу");
      }
    } catch {
      setError("Помилка мережі");
    }
  };

  const submitOnEnter = (event: any) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 px-4">
      <div className="w-full max-w-md bg-white rounded-2xl shadow-lg p-8">
        <h1 className="text-2xl font-bold mb-6 text-center">Вхід</h1>

        <label className="block mb-2 text-sm font-medium text-gray-600">Телефон</label>
        <input
          className="w-full border border-gray-300 rounded-xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="+380-XX-XXX-XX-XX"
          onKeyPress={submitOnEnter}
          autoComplete="username"
        />
        <label className="block mb-2 text-sm font-medium text-gray-600">Пароль</label>
        <input
          className="w-full border border-gray-300 rounded-xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Ваш пароль"
          onKeyPress={submitOnEnter}
          autoComplete="username"
        />

        {error && <p className="text-sm text-red-500 mt-2">{error}</p>}

        <button
          className="mt-6 w-full bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 rounded-xl transition"
          onClick={handleLogin}
        >
          Вхід
        </button>
      </div>
    </div>
  );
}
