import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../config";
import { Menu } from "../components/ui/menu";
import { useProfile } from "../context/ProfileContext";

export default function EditUserPage() {
  const { profile, loading } = useProfile();
  const { account } = useParams();
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token || !account) return;

    fetch(API.USER(String(account)), {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          setTimeout(() => setError(""), 5000);
        } else {
          setUser(data);
        }
      }).catch((err) => {
      setError(`Помилка сервера ${err}`);
      setTimeout(() => setError(""), 5000);
    });
  }, [account, token]);

  const handleChange = (field: string, value: any) => {
    setUser((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleNestedChange = (group: string, field: string, value: string) => {
    setUser((prev: any) => ({
      ...prev,
      [group]: {
        ...prev[group],
        [field]: value,
      },
    }));
  };

  const handleRolesChange = (group: string, field: string, value: string) => {
    const update = { ...user, [field]: [value] }
    setUser((prev: any) => ({
      ...prev,
      ...update
    }));
  };

  const handleSave = async () => {
    if (!token || !account) return;
    try {
      const res = await fetch(API.USER(String(account)), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(user),
      });

      const data = await res.json();
      if (res.ok) {
        setSuccess("Успішно збережено ✅");
        setTimeout(() => setSuccess(""), 5000);
      } else {
        setError(data.error || "Не вдалося оновити");
        setTimeout(() => setError(""), 5000);
      }
    } catch (err) {
      setError(`Помилка сервера ${err}`);
      setTimeout(() => setError(""), 5000);
    }
  };

  if (loading) return <p className="text-center p-4">Завантажується...</p>;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-100">

      <Menu profile={profile}/>

      <div className="max-w-xl mx-auto p-6 bg-white shadow rounded-xl mt-6 space-y-4">
        <h2 className="text-xl font-bold text-indigo-700 text-center">Edit User: {account}</h2>

        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-600">{success}</p>}

        <div>
          <label className="block text-sm font-medium">ПІБ</label>
          <input
            className="w-full border px-3 py-2 rounded mt-1"
            value={user?.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Пароль (ОНОВИТИ) якщо пустий то не змінюється</label>
          <input
            type="password"
            className="w-full border px-3 py-2 rounded mt-1"
            value={user?.password || ""}
            onChange={(e) => handleChange("password", e.target.value)}
            autoComplete="password"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">СТ</label>
          <input
            className="w-full border px-3 py-2 rounded mt-1"
            value={user?.section || ""}
            onChange={(e) => handleChange("section", e.target.value)}
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Телефон (основний)</label>
          <input
            className="w-full border px-3 py-2 rounded mt-1"
            value={user?.phones?.main || ""}
            onChange={(e) => handleNestedChange("phones", "main", e.target.value)}
            autoComplete="phone"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <div>
            <label className="block text-sm font-medium">КОД НС2 (СИСТЕМИ)</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              value={user?.accounts?.prefix || ""}
              onChange={(e) => handleNestedChange("accounts", "prefix", e.target.value)}
              readOnly
              disabled
            />
          </div>

          <div>
            <label className="block text-sm font-medium">КОД НС2 (ПРИЙШОВ)</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              value={user?.accounts?.raw || ""}
              readOnly
              disabled
            />
          </div>

          <div>
            <label className="block text-sm font-medium">КОД НС2 (Уточнення)</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              value={user?.accounts?.suffix || ""}
              readOnly
              disabled
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Роль</label>
            <select
              className="w-full border px-3 py-2 rounded mt-1"
              value={user?.roles?.[0] || "user"}
              onChange={(e) => handleRolesChange("accounts", "roles", e.target.value)}
            >
              <option value="USER">Користувач</option>
              <option value="ADMIN">Адміністратор</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <div>
            <label className="block text-sm font-medium">Viber</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              value={user?.viber || ""}
              onChange={(e) => handleChange("viber", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Viber Відправляти?</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              type="checkbox"
              checked={user?.viberSend || false}
              onChange={(e) => handleChange("viberSend", e.target.checked)}
            />
          </div>

        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <div>
            <label className="block text-sm font-medium">Telegram</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              value={user?.telegram || ""}
              onChange={(e) => handleChange("telegram", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Telegram Відправляти?</label>
            <input
              className="w-full border px-3 py-2 rounded mt-1"
              type="checkbox"
              checked={user?.telegramSend || false}
              onChange={(e) => handleChange("telegramSend", e.target.checked)}
            />
          </div>

        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={() => navigate("/users")}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-sm rounded"
          >
            Назад
          </button>

          <button
            onClick={handleSave}
            className="px-4 py-2 bg-indigo-600 text-white hover:bg-indigo-700 text-sm rounded"
          >
            Зберегти
          </button>
        </div>

        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-600">{success}</p>}

      </div>
    </div>
  );
}
