// src/config.ts

// const BASE_URL = "http://localhost:13380/api";
const BASE_URL = "https://o.app.org.ua/api";

export const API = {
  BASE_URL,
  LOGIN: `${BASE_URL}/login`,
  PROFILE: `${BASE_URL}/profile`,
  USERS: `${BASE_URL}/users`,
  RECORD: (account: string) => `${BASE_URL}/record/${encodeURIComponent(account)}`,
  USER: (account: string) => `${BASE_URL}/user/${encodeURIComponent(account)}`,
};

